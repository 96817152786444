export const About = () => {
  const persons = [
    {
      id: 1,
      firstName: "Tamás",
      lastName: "Sáhó",
      bio: "Som vyučený maliar s 15 ročnou praxou. Zodpovedne pristupujem k práci, a garantujem Vám čo najvyššiu kvalitu.",
      img: require("../../images/avatar.png"),
    },
    {
      id: 2,
      firstName: "Piciny",
      lastName: "Dezider",
      bio: "Maliar, natierač s 40 ročnou praxou. Práce v interieroch a v exterieroch. Zodpovednosť a spoľahlivosť.",
      img: require("../../images/avatar.png"),
    },
  ];

  return (
    <div className="mx-auto container p-6 md:p-0">
      <section id="aboutus">
        {persons.map((person) => (
          <div
            key={person.id}
            className="my-28 flex flex-col md:flex-row gap-3 items-center justify-center"
          >
            <img
              className="w-32 md:w-40 select-none"
              src={person.img}
              alt="avatar"
            />
            <div className="text-left max-w-md">
              <h2 className="text-2xl text-slate-500">{`${person.lastName} ${person.firstName}`}</h2>
              <p className="mt-2 text-slate-400">{person.bio}</p>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};
