import {
  EnvelopeIcon,
  PaperAirplaneIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import React from "react";

export const Contact = () => {
  const contacts = [
    "+421 905 544 679",
    "+421 905 573 841",
    "fulop1693@gmail.com",
  ];

  return (
    <div className="mx-auto container p-4">
      <section
        className="my-28 flex flex-col items-center justify-center"
        id="contact"
      >
        <div className="text-slate-500 flex flex-col items-center justify-center">
          <PaperAirplaneIcon
            className="-rotate-45 transition-all"
            style={{ strokeWidth: 0.5 }}
          />
          <h2 className="mt-2 text-4xl text-center font-light">Kontakt</h2>
        </div>

        <div className="mt-10 grid grid-cols-1 gap-4" id="contacts">
          {contacts.map((contact) => (
            <div
              key={contact}
              className="flex items-center gap-2 text-slate-500"
            >
              {contact.startsWith("+") ? (
                <>
                  <PhoneIcon className="w-8 h-8" />
                  <a href={`tel:${contact}`}>{contact}</a>
                </>
              ) : (
                <>
                  <EnvelopeIcon className="w-8 h-8" />
                  <a href={`mailto:${contact}`}>{contact}</a>
                </>
              )}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
