import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Footer } from "../core/components/Footer";
import { Nav } from "../core/components/Nav";
import { Contact } from "../Pages/Contact/Contact";
import { Home } from "../Pages/Home/Home";
import { About } from "../Pages/About/About";
import { Shape } from "../core/components/Shape/Shape";
export const App = () => {
  return (
    <BrowserRouter>
      <Shape />
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};
